import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type MarketplaceData = {
	[key: string]: any
}

const contextName = 'MARKETPLACE_DATA_CTX'

export function setMarketplaceDataState(data?: MarketplaceData) {
	const state = writable<MarketplaceData>({
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getMarketplaceDataState() {
	return getContext<Writable<MarketplaceData>>(contextName)
}
